import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { TicketsListLocationProvider } from './contexts/LocationContext';
import { TicketsListPageContextProvider } from './contexts/TicketsListPageContext';
import { TicketsListTable } from 'components/TicketsListTable';

export const TicketsListPage = () => {
  return (
    <TicketsListLocationProvider>
      <TicketsListPageContextProvider>
        <ScrollWrapper>
          <Wrapper>
            <TicketsListTable />
          </Wrapper>
        </ScrollWrapper>
      </TicketsListPageContextProvider>
    </TicketsListLocationProvider>
  );
};

const ScrollWrapper = styled.div`
  height: calc(100vh - 56px);
  background-color: ${Colors.Gray5};
  width: 100%;
  overflow-y: auto;
  padding: 32px;
`;

const Wrapper = styled.div`
  background-color: ${Colors.White0};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Gray10};
  padding: var(--Space-Margin-marginXXS, 20px)
    var(--Space-Margin-marginMD, 20px);
`;
