/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Outlet,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { Body, Heading, RKTheme } from '@robinpowered/design-system';
import { Header } from '@robinpowered/dashboard-apps-header';
import { cssReset } from './cssReset';
import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary';
import { config } from 'config';
import {
  useApolloContext,
  useAmplitude,
  useAuthContext,
  ApolloContextProvider,
  AmplitudeProvider,
  AuthContextProvider,
} from 'contexts';
import { PageMessage } from 'components/PageMessage';
import { i18n } from 'i18n';
import { MeetingServicePage, ServiceRequestPage } from 'pages';
import { Sidebar } from 'components/Sidebar/Sidebar';
import styled from '@emotion/styled';
import { ConfigProvider, RobinTheme } from '@robinpowered/ui-kit';
import { TicketsListPage } from 'pages/TicketsListPage/TicketsListPage';

export const App = (): JSX.Element => {
  return (
    <I18nextProvider i18n={i18n}>
      <GlobalErrorBoundary>
        <Global styles={cssReset} />
        <RKTheme>
          <ConfigProvider theme={{ ...RobinTheme, cssVar: { key: 'tickets' } }}>
            <BrowserRouter>
              <ApolloContextProvider>
                <AuthContextProvider>
                  <AmplitudeProvider>
                    <HelmetProvider>
                      <DocumentHead />
                      <RootRouteTree />
                    </HelmetProvider>
                  </AmplitudeProvider>
                </AuthContextProvider>
              </ApolloContextProvider>
            </BrowserRouter>
          </ConfigProvider>
        </RKTheme>
      </GlobalErrorBoundary>
    </I18nextProvider>
  );
};

export enum TicketsRoutes {
  WORKFLOWS = 'workflows',
  TICKETS_LIST = 'list',
  ISSUE_REPORTS = 'issue-reports',
  REQUEST = 'request-form',
}

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation(['error', 'MeetingServicePage']);
  const { loading, isLoggedIn, error } = useAuthContext();

  useEffect(() => {
    /* @TODO: config.env always comes back as "production" */
    const redirectToDashboard =
      !loading && config.env !== 'development' && !isLoggedIn;

    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [loading, isLoggedIn]);

  if (!loading) {
    if (!isLoggedIn) {
      return <PageMessage title={t('unauthenticated')} />;
    }
    if (error) {
      return (
        <PageMessage
          title={t('authError.title')}
          message={t('authError.message')}
        />
      );
    }
  }

  return (
    <Routes>
      <Route path="/" element={<span>{t('missing_slug')}</span>} />
      <Route
        path="/"
        element={
          <>
            <HeaderWithParam />
            <Outlet />
          </>
        }
      >
        <Route path=":orgSlug/tickets" element={<DashboardLayout />}>
          <Route index element={<TicketsListPage />} />
        </Route>
        <Route
          path={`:orgSlug/tickets/${TicketsRoutes.WORKFLOWS}`}
          element={<DashboardLayout />}
        >
          <Route index element={<MeetingServicePage />} />
        </Route>
        <Route
          path={`:orgSlug/tickets/${TicketsRoutes.TICKETS_LIST}`}
          element={<DashboardLayout />}
        >
          <Route index element={<TicketsListPage />} />
        </Route>
        <Route
          path={`:orgSlug/tickets/${TicketsRoutes.ISSUE_REPORTS}`}
          element={<DashboardLayout />}
        >
          <Route index element={<div>Issue report</div>} />
        </Route>
      </Route>
      <Route
        path={`:orgSlug/tickets/${TicketsRoutes.REQUEST}`}
        element={
          <>
            <HeaderWithParam headless />
            <ServiceRequestPage />
          </>
        }
      />
    </Routes>
  );
};

const DashboardLayout = (): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 56px)',
      }}
    >
      <Sidebar />
      <Outlet />
    </div>
  );
};

// This is a custom implementation for the Service Request Form, which is consumed
// as a microfrontend in an iframe.  We need to remove the header and redirects so the
// user is not trapped or lost inside the iframe
const HeaderWithParam = ({ headless }: { headless?: boolean }): JSX.Element => {
  const { t } = useTranslation('MeetingServiceRequest');
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const { tenantId } = useApolloContext();
  const { trackEvent } = useAmplitude();
  const { loading, isLoggedIn, setOrgSlug, error } = useAuthContext();

  useEffect(() => {
    setOrgSlug(orgSlug);
  }, [orgSlug, setOrgSlug]);

  useEffect(() => {
    /* @TODO: config.env always comes back as "production" */
    const redirectToDashboard =
      !loading && config.env !== 'development' && !isLoggedIn;

    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [loading, isLoggedIn]);

  if (!loading && headless && (error || !isLoggedIn)) {
    return (
      <ServiceRequestFormErrorPage>
        <Heading.Small>{t('errors.global.title')}</Heading.Small>
        <Body.Small>{t('errors.global.message')}</Body.Small>
      </ServiceRequestFormErrorPage>
    );
  }

  if (!loading) {
    if (!isLoggedIn) {
      return <PageMessage title={t('unauthenticated')} />;
    }
    if (error) {
      return (
        <PageMessage
          title={t('authError.title')}
          message={t('authError.message')}
        />
      );
    }
  }

  return (
    <>
      {!headless && (
        <Header
          activeOrgSlug={orgSlug ?? ''}
          hasTenant={!!tenantId}
          amplitudeEventHandler={trackEvent}
        />
      )}
    </>
  );
};

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};

const ServiceRequestFormErrorPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 8px;
  text-align: center;
`;
