import { Tag } from '@robinpowered/ui-kit';

import { MeetingServiceRequestStatus } from 'generated';
import { useTranslation } from 'react-i18next';

type Props = {
  status: MeetingServiceRequestStatus;
};

export const ServiceStatus = ({ status }: Props) => {
  const { t } = useTranslation('TicketsListPage');

  const renderStatus = (status: MeetingServiceRequestStatus) => {
    switch (status) {
      case MeetingServiceRequestStatus.Blocked:
        return <Tag color="warning">{t('tickets.status.blocked')}</Tag>;
      case MeetingServiceRequestStatus.InProgress:
        return <Tag color="processing">{t('tickets.status.in_progress')}</Tag>;
      case MeetingServiceRequestStatus.Done:
        return <Tag color="success">{t('tickets.status.done')}</Tag>;
      case MeetingServiceRequestStatus.Rejected:
        return <Tag color="error">{t('tickets.status.rejected')}</Tag>;
      case MeetingServiceRequestStatus.Cancelled:
        return <Tag color="error">{t('tickets.status.cancelled')}</Tag>;
      default:
        return <Tag color="default">{t('tickets.status.todo')}</Tag>;
    }
  };

  return <>{renderStatus(status)}</>;
};
