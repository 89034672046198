import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { MeetingServices } from 'components/MeetingServices/MeetingServices';
import { ServiceFormContainer } from 'components/ServiceForm';
import {
  MeetingServicesContextProvider,
  useMeetingServicesContext,
} from 'pages/MeetingServicePage/contexts/ServicesContext';
import { ToastProvider, useToast } from 'contexts';
import { MeetingServicesLocationProvider } from 'pages/MeetingServicePage/contexts/LocationContext';
import { useTranslation } from 'react-i18next';
import {
  MeetingServicePageContextProvider,
  ServiceFormTabTypes,
  useMeetingServicePageContext,
} from './contexts/MeetingServicePageContext';
import { Tabs } from '@robinpowered/ui-kit';

const MeetingServicesContainer = () => {
  const { t } = useTranslation('MeetingServicePage');
  const { refetchMeetingServices, refetchMeetingServicesCount } =
    useMeetingServicesContext();
  const toast = useToast();
  const { setCurrentPage, currentPage, serviceFormOpen, setServiceFormOpen } =
    useMeetingServicePageContext();

  return (
    <>
      <ScrollWrapper>
        <MeetingServiceWrapper>
          <StyledTabs
            defaultActiveKey={currentPage}
            activeKey={currentPage}
            items={[
              {
                key: 'meetingService',
                label: t(`navHeader.meetingService`),
                children: <MeetingServices />,
                style: { height: '100%' },
              },
            ]}
            onChange={(activeKey) =>
              setCurrentPage(activeKey as ServiceFormTabTypes)
            }
          />
        </MeetingServiceWrapper>
      </ScrollWrapper>
      {serviceFormOpen && (
        <ServiceFormContainer
          onSuccessfulSubmit={(status: 'added' | 'updated') => {
            refetchMeetingServicesCount();
            setServiceFormOpen(false);
            toast.success(t(`toasts.service_${status}`));
            refetchMeetingServices();
          }}
        />
      )}
    </>
  );
};

export const MeetingServicePage = () => {
  return (
    <ToastProvider>
      <MeetingServicesLocationProvider>
        <MeetingServicesContextProvider>
          <MeetingServicePageContextProvider>
            <MeetingServicesContainer />
          </MeetingServicePageContextProvider>
        </MeetingServicesContextProvider>
      </MeetingServicesLocationProvider>
    </ToastProvider>
  );
};

const ScrollWrapper = styled.div`
  height: calc(100vh - 56px);
  background-color: ${Colors.Gray5};
  width: 100%;
  overflow-y: auto;
  padding: 32px;
`;

const MeetingServiceWrapper = styled.div`
  background-color: ${Colors.White0};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${Colors.Gray10};
  padding: var(--Space-Margin-marginXXS, 4px) var(--Space-Margin-marginMD, 20px);
`;

const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-tabs-content {
    height: 100%;
  }
`;
