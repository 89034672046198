import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TicketsRoutes } from 'App';

const getLastRoute = (pathname: string) => pathname.split('/').pop();

export const useManageSidebar = () => {
  const { t } = useTranslation('Sidebar');
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  // Default selected key is 'list'
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    TicketsRoutes.TICKETS_LIST,
  ]);
  const [isOpen, setIsOpen] = useState(true);

  const navItems = [
    {
      label: t('settings.tickets'),
      key: 'list',
      onClick: () =>
        navigate(`/${orgSlug}/tickets/${TicketsRoutes.TICKETS_LIST}`),
    },
    {
      label: t('settings.workflows'),
      key: 'workflows',
      onClick: () => navigate(`/${orgSlug}/tickets/${TicketsRoutes.WORKFLOWS}`),
    },
  ];

  // Set selected keys based on the current route. These keys are used by Ant
  // design to determine the active nav item in the sidebar.
  useEffect(() => {
    const route = getLastRoute(routerLocation.pathname);

    if (
      route &&
      Object.values(TicketsRoutes).includes(route as TicketsRoutes)
    ) {
      setSelectedKeys([route]);
    }
  }, [routerLocation.pathname]);

  return { navItems, selectedKeys, setIsOpen, isOpen };
};
