/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table as RobinTable } from '@robinpowered/ui-kit';
import {
  MeetingServicesTableContextProvider,
  useMeetingServicesTableContext,
} from './contexts';
import { BulkActionBanner } from 'components/common/BulkActionBanner';
import { ConfirmDelete } from './components/ConfirmDelete';
import { Pagination } from './components/Pagination/Pagination';
import styled from '@emotion/styled';
import { useMeetingServicesContext } from 'pages/MeetingServicePage/contexts/ServicesContext';
import { ListMeetingServicesSortByCriteria, SortOrder } from 'generated';
import { useRef } from 'react';
import { useFixAntTableHeight } from 'hooks/useFixAntTableHeight';

const ServicesTableContainer = () => {
  const {
    tableData,
    columns,
    selectedMeetingServices: selectedMeetingServicesIds,
    showConfirmDelete,
    loadingMeetingServiceTable,
    rowSelection,
    toastContextHolder,
    buildAssigneeFiltersForQuery,
    setSkipItems,
  } = useMeetingServicesTableContext();

  const {
    setFiltersForMeetingServicesQuery,
    setSortByForMeetingServicesQuery,
  } = useMeetingServicesContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const tableHeight = useFixAntTableHeight(contentRef, {
    headerHeight: 65,
    footerHeight: 75,
  });

  // Fixes a bug with ant table: https://github.com/ant-design/ant-design/issues/26621
  const scroll =
    !loadingMeetingServiceTable && tableData.length > 0
      ? {
          x: 'max-content',
          y: tableHeight,
        }
      : undefined;

  return (
    <TableWrapper ref={contentRef}>
      {toastContextHolder}
      {showConfirmDelete && <ConfirmDelete />}

      <BulkActionBanner
        selectedMeetingServicesIds={selectedMeetingServicesIds}
      />

      <RobinTable
        scroll={scroll}
        sticky={{ offsetHeader: 0 }}
        dataSource={tableData}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          // @TODO: We should lift this onchange out to the contexts/hooks above
          // but need to keep hit here until we can import the sorting/filtering types
          // from ant
          const assigneeFilter = buildAssigneeFiltersForQuery(
            filters.assignee as string[]
          );

          setFiltersForMeetingServicesQuery((prev) => {
            return {
              ...prev,
              belongsToAnyCategoryId: (filters.category as string[] | null)
                ? [...(filters.category as string[])]
                : [],
              assignedToAny: assigneeFilter,
            };
          });

          setSortByForMeetingServicesQuery((prev) => {
            if (
              !sorter ||
              !(sorter as any).order ||
              !(sorter as any).columnKey
            ) {
              // State was not updating when returning undefined, so using null instead
              // even though the end query expects 'undefined' as its param
              return null;
            }

            const order =
              (sorter as any).order === 'ascend'
                ? SortOrder.Ascending
                : SortOrder.Descending;

            const criteria =
              ((sorter as any).columnKey as string) === 'name'
                ? ListMeetingServicesSortByCriteria.ServiceName
                : (sorter as any).columnKey === 'category'
                ? ListMeetingServicesSortByCriteria.CategoryName
                : ListMeetingServicesSortByCriteria.UpdatedAt;

            return {
              order,
              criteria,
            };
          });

          // Always reset the page to 1 after changing filters
          setSkipItems(0);
        }}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        pagination={false}
        loading={loadingMeetingServiceTable}
      />
      <PaginationWrapper>
        <Pagination />
      </PaginationWrapper>
    </TableWrapper>
  );
};

export const ServicesTable = () => {
  return (
    <MeetingServicesTableContextProvider>
      <ServicesTableContainer />
    </MeetingServicesTableContextProvider>
  );
};

const PaginationWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  justify-content: space-between;

  // Fix to for antdesign td's where we cant access their styles easily
  td {
    vertical-align: middle;
  }
`;
