import { useAuthContext } from 'contexts';
import {
  GetTicketsForTicketsListPageQuery,
  ListMeetingServiceRequestsSortByInput,
  useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery,
  useGetTicketsForTicketsListPageQuery,
} from 'generated';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
} from 'react';
import { useTicketsListLocationContext } from './LocationContext';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';

type RefetchFilters = {
  skip?: number | undefined;
  after?: string | undefined;
};
type TicketsListPageValue = {
  data: GetTicketsForTicketsListPageQuery | undefined;
  refetchTickets: (filters?: RefetchFilters) => void;
  loading: boolean;
  meetingServiceRequestsCount: number;
  refetchMeetingServiceRequestsCount: () => void;
  sortByForListServiceRequestsQuery:
    | ListMeetingServiceRequestsSortByInput
    | null
    | undefined;
  setSortByForListServiceRequestsQuery: Dispatch<
    SetStateAction<ListMeetingServiceRequestsSortByInput | null | undefined>
  >;
};

const TicketsListPageContext = createContext<TicketsListPageValue>({
  data: undefined,
  refetchTickets: () => null,
  loading: true,
  meetingServiceRequestsCount: 0,
  refetchMeetingServiceRequestsCount: () => null,
  setSortByForListServiceRequestsQuery: () => null,
  sortByForListServiceRequestsQuery: null,
});

type Props = {
  children: ReactNode;
};

export const TICKETS_PER_TABLE_PAGE = 15;

export const TicketsListPageContextProvider: FC<Props> = ({ children }) => {
  const { loading: loadingAuth } = useAuthContext();

  const {
    selectedLocations,
    locationsUserCanManage,
    loading: loadingLocations,
  } = useTicketsListLocationContext();

  const [
    sortByForListServiceRequestsQuery,
    setSortByForListServiceRequestsQuery,
  ] = useTenantLocalStorage<ListMeetingServiceRequestsSortByInput | null>(
    'list-meeting-service-requests-table-sort',
    null
  );

  const { data, loading, refetch } = useGetTicketsForTicketsListPageQuery({
    variables: {
      input: {
        first: TICKETS_PER_TABLE_PAGE,
        sortBy: sortByForListServiceRequestsQuery || undefined,
        skip: undefined,
        after: undefined,
      },
    },
    skip: loadingAuth,
  });

  const availableInAnyBuildingIdsOrTheirDescendants = useMemo(() => {
    return selectedLocations.length
      ? selectedLocations.filter((location) => !!location)
      : locationsUserCanManage.map((location) => {
          return location?.id || '';
        });
  }, [selectedLocations, locationsUserCanManage]);

  const {
    data: dataForCountServiceRequests,
    refetch: refetchMeetingServiceRequestsCount,
  } = useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery({
    variables: {
      input: {
        filters: {
          availableInAnyBuildingIdsOrTheirDescendants,
        },
      },
    },
    skip:
      loadingLocations ||
      availableInAnyBuildingIdsOrTheirDescendants.length < 1,
  });

  return (
    <TicketsListPageContext.Provider
      value={{
        meetingServiceRequestsCount:
          dataForCountServiceRequests?.countMeetingServiceRequests.count || 0,
        refetchMeetingServiceRequestsCount: () => {
          // TODO once filters are ready, make this have access to them
          refetchMeetingServiceRequestsCount();
        },
        data,
        loading,
        setSortByForListServiceRequestsQuery,
        sortByForListServiceRequestsQuery,
        refetchTickets: (filters?: RefetchFilters) => {
          // TODO once filters are ready, make this have access to them
          refetch({
            input: {
              first: TICKETS_PER_TABLE_PAGE,
              skip: filters?.skip,
              after: filters?.after,
            },
          });
        },
      }}
    >
      {children}
    </TicketsListPageContext.Provider>
  );
};

export const useTicketsListPageContext = (): TicketsListPageValue => {
  return useContext(TicketsListPageContext);
};
