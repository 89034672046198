import React from 'react';
import styled from '@emotion/styled';
import { Colors, CoreFontWeight } from '@robinpowered/design-system';
import { Menu } from '@robinpowered/ui-kit';
import { ChevronRightOutline } from '@robinpowered/icons';

import { useManageSidebar } from './hooks/useManageSidebar';
import { TicketsRoutes } from 'App';

export const Sidebar = () => {
  const { navItems, selectedKeys, isOpen, setIsOpen } = useManageSidebar();

  return (
    <SideNavigationWrapper
      open={isOpen}
      style={{ cursor: !isOpen ? 'pointer' : 'default' }}
    >
      {isOpen && (
        <SideNavigationContainer>
          <Menu
            items={navItems}
            mode="inline"
            selectedKeys={selectedKeys}
            style={{ border: 'none' }}
            defaultOpenKeys={[TicketsRoutes.WORKFLOWS]}
          />
        </SideNavigationContainer>
      )}
      <SidebarToggle
        onClick={() => setIsOpen(!isOpen)}
        className="sidebarToggle"
        open={isOpen}
        onKeyPress={(e) => {
          if (e.key === 'Enter') setIsOpen(!isOpen);
        }}
        tabIndex={0}
      >
        <ChevronRightOutline
          size={12}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.25s ease',
          }}
        />
      </SidebarToggle>
    </SideNavigationWrapper>
  );
};

const SidebarToggle = styled.div<{ open: boolean }>(
  (props) => `
    align-items: center;
    background-color: ${Colors.White0};
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 6px;
    border-radius: 100%;

    position: absolute;
    right: -10px;
    top: 10px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    visibility: ${props.open ? 'hidden' : 'visible'};
`
);

const SideNavigationContainer = styled.div`
  margin: 25px auto;
`;

const SideNavigationWrapper = styled.div<{ open: boolean }>(
  (props) => `
    width: ${props.open ? 250 : 20}px;
    transition: all 0.25s ease;
    position: relative;
    padding: 12px;
  
    background-color: ${Colors.White0};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

    &:hover {
        .sidebarToggle {
            visibility: visible;
        }
    }
  
    a {
      align-items: center;
      color: ${Colors.Gray80};
      display: flex;
      text-decoration: none;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 10px;
  
      &:hover {
        color: ${Colors.Maroon100};
      }
  
      &.active {
        border-bottom-color: ${Colors.Maroon100};
        color: ${Colors.Maroon100};
        font-weight: ${CoreFontWeight.Medium};
  
        > .new-tag {
          background-color: ${Colors.Maroon100};
        }
      }
    }
  `
);
