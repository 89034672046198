import { Typography } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation('TicketsListPage');

  return (
    <PageHeader>
      <Typography.Title style={{ fontSize: '22px' }}>
        {t('header')}
      </Typography.Title>
    </PageHeader>
  );
};

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
