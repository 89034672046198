/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table as RobinTable } from '@robinpowered/ui-kit';
import { Pagination } from './components/Pagination/Pagination';
import styled from '@emotion/styled';
import {
  TicketsListTableContextProvider,
  useTicketsListTableContext,
} from './contexts';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { ListMeetingServiceRequestsSortByCriteria, SortOrder } from 'generated';
import { Header } from './Header';
import { useRef } from 'react';
import { useFixAntTableHeight } from 'hooks/useFixAntTableHeight';

const TicketsListTableContainer = () => {
  const { setSortByForListServiceRequestsQuery } = useTicketsListPageContext();
  const { tableData, columns, loadingTicketsListTable, setSkipItems } =
    useTicketsListTableContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const tableHeight = useFixAntTableHeight(contentRef, {
    headerHeight: 80,
    footerHeight: 75,
  });

  // Fixes a bug with ant table: https://github.com/ant-design/ant-design/issues/26621
  const scroll =
    !loadingTicketsListTable && tableData.length > 0
      ? {
          x: 'max-content',
          y: tableHeight,
        }
      : { x: 1200 };

  return (
    <TableWrapper ref={contentRef}>
      <Header />
      <RobinTable
        sticky={{ offsetHeader: 0 }}
        scroll={scroll}
        dataSource={tableData}
        columns={columns}
        style={{ alignSelf: 'flex-start', flex: 1 }}
        pagination={false}
        loading={loadingTicketsListTable}
        onChange={(pagination, filters, sorter) => {
          setSortByForListServiceRequestsQuery((prev) => {
            if (
              !sorter ||
              !(sorter as any).order ||
              !(sorter as any).columnKey
            ) {
              // State was not updating when returning undefined, so using null instead
              // even though the end query expects 'undefined' as its param
              return null;
            }

            const order =
              (sorter as any).order === 'ascend'
                ? SortOrder.Ascending
                : SortOrder.Descending;

            const criteria =
              ((sorter as any).columnKey as string) === 'createdAt'
                ? ListMeetingServiceRequestsSortByCriteria.CreatedAt
                : (sorter as any).columnKey === 'updatedAt'
                ? ListMeetingServiceRequestsSortByCriteria.UpdatedAt
                : ListMeetingServiceRequestsSortByCriteria.UpdatedAt;

            return {
              order,
              criteria,
            };
          });

          // Always reset the page to 1 after changing filters
          setSkipItems(0);
        }}
      />
      <PaginationWrapper>
        <Pagination />
      </PaginationWrapper>
    </TableWrapper>
  );
};

export const TicketsListTable = () => {
  return (
    <TicketsListTableContextProvider>
      <TicketsListTableContainer />
    </TicketsListTableContextProvider>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;

  // Fix to for antdesign td's where we cant access their styles easily
  td {
    vertical-align: middle;
  }
`;
